import {
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button
} from '@chakra-ui/react'
import React from 'react'

const ModalAlert = ({
  isOpen,
  onClose,
  title,
  content,
  textBtnLeft,
  onClickBtnLeft,
  textBtnRight,
  onClickBtnRight
}) => {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{content}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClickBtnLeft}>{textBtnLeft}</Button>
            <Button colorScheme='red' onClick={onClickBtnRight} ml={3}>
              {textBtnRight}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default ModalAlert
