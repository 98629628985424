import { STORAGE } from 'constants'
import { t } from 'i18next'
import { useEffect } from 'react'
// Assets
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getInformation } from 'utils'
import { setCookie } from 'utils'

function HandleLogin() {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
      const loginInfo = getInformation(location?.search?.split('=')[1])
      localStorage.setItem('auth_user', JSON.stringify(loginInfo.user));
      if(!!loginInfo && !!loginInfo.user && !!loginInfo.user.is_admin){
        setCookie(STORAGE.ACCESS_TOKEN, location.search.split('=')[1], {
          path: '/'
        })
        history.push('/admin/courses')
      } else {
        toast.error(t('unauthorized'))
        history.push('/auth/login')
      }
  }, [location])

  return <></>
}

export default HandleLogin
