export const STORAGE = {
  ACCESS_TOKEN: 'access_token',
}

export const RADIO_DATA_VALID_PERIOD = [
  { id: '1', value: 'pages.course_form.valid_period.indefinitely' },
  { id: '2', value: '' }
]

export const WORKING_STATUS = [
  { id: '', name: '' },
  { id: '1', name: '在職者' },
  { id: '2', name: '休職者' },
  { id: '3', name: '退職者' },
  { id: '4', name: '内定者' },
]

export const RADIO_DATA_TARGET_RENEWAL_CYCLE = [
  { id: '1', value: 'pages.course_form.renew_cycle.manual' },
  { id: '2', value: 'pages.course_form.renew_cycle.auto' }
]

export const PATHNAME = {
  CREATE_COURSE: '/admin/courses/create',
  CREATE_DOCUMENT: '/admin/documents/create',
}

export const JOB = [
  {
    id: '',
    name: ''
  },
  {
    id: '事業部責任者',
    name: '事業部責任者',
  },
  {
    id: '事業部副責任者',
    name: '事業部副責任者',
  },
  {
    id: '事業所責任者',
    name: '事業所責任者',
  },
  {
    id: '事業所副責任者',
    name: '事業所副責任者',
  },
  {
    id: 'サ責一般',
    name: 'サ責一般'
  },
  {
    id: 'サ責リーダー',
    name: 'サ責リーダー',
  },
  {
    id: '介護職',
    name: '介護職',
  },
  {
    id: '訪問介護員',
    name: '訪問介護員',
  },
  {
    id: '営業',
    name: '営業',
  },
  {
    id: '事務',
    name: "事務"
  },
  {
    id: 'ケアマネジャー',
    name: 'ケアマネジャー',
  },
  {
    id: '看護師',
    name: '看護師',
  },
  {
    id: '相談員',
    name: '相談員',
  },
  {
    id: '運転手',
    name: '運転手'
  },
  {
    id: '調理補助',
    name: '調理補助',
  },
  {
    id: '機能訓練指導員',
    name: '機能訓練指導員'
  },
  {
    id: '理学療法士',
    name: '理学療法士'
  },
  {
    id: '顧問',
    name: '顧問'
  },
]

export const ROLE_2_NAME = [
  {
    id: '',
    name: ''
  },
  {
    id: '取締役',
    name: '取締役'
  },
  {
    id: '監査役',
    name: '監査役'
  },
  {
    id: '執行役員',
    name: '執行役員'
  },
  {
    id: '理事',
    name: '理事'
  },
  {
    id: '統括参与',
    name: '統括参与'
  },
  {
    id: '参与',
    name: '参与'
  },
  {
    id: '部長',
    name: '部長'
  },
  {
    id: '統括参事',
    name: '統括参事'
  },
  {
    id: '参事',
    name: '参事'
  },
  {
    id: '主任',
    name: '主任'
  },
  {
    id: '一般',
    name: '一般'
  },
  {
    id: '派遣',
    name: '派遣'
  },
  {
    id: '顧問',
    name: '顧問'
  },
  {
    id: '相談役',
    name: '相談役'
  },
]


export const ROLE_1_NAME = [
  {
    id: '',
    name: ''
  },
  {
    id: 'オペレーター 役割',
    name: 'オペレーター 役割'
  },
  {
    id: 'ケアマネジャー 役割',
    name: 'ケアマネジャー 役割'
  },
  {
    id: 'コーディネーター 役割',
    name: 'コーディネーター 役割'
  },
  {
    id: 'コンシェルジュ 役割',
    name: 'コンシェルジュ 役割'
  },
  {
    id: 'サービス提供責任者 役割',
    name: 'サービス提供責任者 役割'
  },
  {
    id: '営業 役割',
    name: '営業 役割'
  },
  {
    id: '営業事務 役割',
    name: '営業事務 役割'
  },
  {
    id: '介護職 役割',
    name: '介護職 役割'
  },
  {
    id: '看護師 役割',
    name: '看護師 役割'
  },
  {
    id: '企画 役割',
    name: '企画 役割'
  },
  {
    id: '機能訓練指導員 役割',
    name: '機能訓練指導員 役割'
  },
  {
    id: '計画作成担当者 役割',
    name: '計画作成担当者 役割'
  },
  {
    id: '事務 役割',
    name: '事務 役割'
  },
  {
    id: '准看護師 役割',
    name: '准看護師 役割'
  },
  {
    id: '調理補助 役割',
    name: '調理補助 役割'
  },
  {
    id: '保健師 役割',
    name: '保健師 役割'
  },
  {
    id: '訪問介護員 役割',
    name: '訪問介護員 役割'
  },
  {
    id: '理学療法士 役割',
    name: '理学療法士 役割'
  },
]

export const POSITION = [
  {
    id: '',
    name: ''
  },
  {
    id: '取締役',
    name: '取締役'
  },
  {
    id: '監査役',
    name: '監査役'
  },
  {
    id: '執行役員',
    name: '執行役員'
  },
  {
    id: '理事',
    name: '理事'
  },
  {
    id: '統括参与',
    name: '統括参与'
  },
  {
    id: '参与',
    name: '参与'
  },
  {
    id: '統括参事',
    name: '統括参事'
  },
  {
    id: '参事',
    name: '参事'
  },
  {
    id: '主任',
    name: '主任'
  },
  {
    id: '主事',
    name: '主事'
  },
  {
    id: '一般',
    name: '一般'
  },
  {
    id: '顧問',
    name: '顧問'
  },
]

export const EMPLOYMENT_TYPE = [
  {
    id: '',
    name: ''
  },
  {
    id: '役員',
    name: '役員'
  },
  {
    id: '正社員',
    name: '正社員'
  },
  {
    id: '新）正社員',
    name: '新）正社員'
  },
  {
    id: '契約社員Ａ',
    name: '契約社員Ａ'
  },
  {
    id: '新）契約社員Ａ',
    name: '新）契約社員Ａ'
  },
  {
    id: '非常勤ケアマネ',
    name: '非常勤ケアマネ'
  },
  {
    id: '事務アルバイト',
    name: '事務アルバイト'
  },
  {
    id: '訪問介護員（日給）',
    name: '訪問介護員（日給）'
  },
  {
    id: '訪問介護員（登録）',
    name: '訪問介護員（登録）'
  },
]

export const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
export const SIZE_3MB = 3000000;
export const PER_PAGE_DEFAULT_EMPLOYEES = 50;

export const CSV_EXTENSION = 'csv';

export const OPTIONS_TARGET_GROUP = [
  {
    name: 'common.option.all_employee',
    id: 1
  },
  {
    name: 'common.option.career_enhancement',
    id: 2
  },
  {
    name: 'common.option.professional_system',
    id: 3
  },
  {
    name: 'common.option.skill_up',
    id: 4
  },
  {
    name: 'common.option.registered_visiting_caregiver',
    id: 5
  },
  {
    name: 'common.option.penalty',
    id: 6
  }
];
