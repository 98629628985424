import { Button } from '@chakra-ui/react'
import React from 'react'

const GreenButton = ({ text, onClick, type = 'button', isLoading, disabled, mr }) => {
    return (
        <Button mr={mr} colorScheme='green' onClick={onClick} type={type} disabled={disabled} paddingLeft={'26px'} paddingRight={'26px'} isLoading={isLoading} loadingText={text} fontSize="14px" size={'sm'} >
            {text}
        </Button>
    )
}

export default GreenButton
