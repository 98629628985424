import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { t } from "i18next"
import { useGetListAdmin, useUpdateEmployee } from "hooks/useEmployee";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  useColorModeValue
} from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Pagination from "components/Pagination/Pagination";
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DeleteButton from 'components/Buttons/DeleteButton';
import { useModalState } from 'hooks/useModalState'
import ModalConfirmAdmin from 'components/modals/ModalConfirmAdmin'

const EmployeeList = () => {
  const history = useHistory()
  const [searchName, setSearchName] = useState('')
  const mutationUpdate = useUpdateEmployee()
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [params, setParams] = useState({
    page: 1,
    limit: 15,
    name: '',
    sortType: -1,
    sort: 'name'
  })
  const [currentId, setCurrentId] = useState()
  const [selectedName, setSelectedName] = useState()

  const { data, isLoading, error } = useGetListAdmin(params)
  const tableData = data?.result?.data
  const total = data?.result?.meta?.total

  const enterSearch = (e) => {
    if (e.key === 'Enter') {
      search()
    }
  }

  const search = () => {
    setParams(prevState => {
      const tempData = { ...prevState }
      tempData.name = searchName
      tempData.page = 1
      return tempData
    })
  }

  /*const changeSort = (typeParams) => {
    setParams(prevState => {
      const tempData = { ...prevState }
      tempData.sortType = typeParams
      return tempData
    })
  }*/

  const changeSort = (sortBy) => {
    setParams(prevState => {
      const tempData = { ...prevState }
      tempData.sort = sortBy
      if (tempData.sortType) {
        tempData.sortType === -1 ? tempData.sortType = 1 : tempData.sortType = -1
      } else {
        tempData.sortType = 1
      }

      return tempData
    })
  }

  const { modalState, open, close, closeAll } = useModalState({
    modalConfirmAdmin: false,
  })
  const onSubmit = (id) => {
    mutationUpdate.mutate(id)
    close('modalConfirmAdmin')
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "40px" }}>
      <Card>
        <CardHeader p="6px 0px 40px 0px">
          <Box justifyContent={'space-between'} >
            <Text fontSize='xl' fontWeight='bold'>
              {t("pages.employee.title")}
            </Text>
          </Box>
          <Flex align="end">
            <Box w="50%">
              <FormControl>
                <Flex mt="20px">
                  <FormLabel w="100px" mt={{ base: "7px" }} fontSize='14px'>
                    {t("pages.employee.label_search")}
                  </FormLabel>
                  <Input w="200px" fontSize={'14px'} value={searchName} onChange={(e) => setSearchName(e.target.value)} onKeyDown={enterSearch} />
                  <PrimaryButton
                    text={t("common.search")}
                    onClick={search}
                    styles={{ ml: '15px', mt: '4px', fontSize: '14px' }}
                  />
                </Flex>
              </FormControl>
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant="simple" w="100%" size={'xsm'}>
              <Thead>
                <Tr>
                  <Th borderColor={borderColor} color="black" width="4%">
                  </Th>
                  <Th borderColor={borderColor} color="black" fontSize='14px' w="10%">
                    {t("pages.employee.code")}
                    {!params.sortType || (params.sort === 'code' && params.sortType === -1) ? (
                        <ChevronDownIcon
                            fontSize="24px"
                            cursor="pointer"
                            color="black"
                            onClick={() => changeSort('code')}
                        />
                    ) : (
                        <ChevronUpIcon
                            fontSize="24px"
                            cursor="pointer"
                            color="black"
                            onClick={() => changeSort('code')}
                        />
                    )}
                  </Th>
                  <Th borderColor={borderColor} color="black" w="20%" fontSize='14px'>
                    {t("pages.employee.name")}
                    {!params.sortType || (params.sort === 'name' && params.sortType === -1) ? (
                        <ChevronDownIcon
                            fontSize="24px"
                            cursor="pointer"
                            color="black"
                            onClick={() => changeSort('name')}
                        />
                    ) : (
                        <ChevronUpIcon
                            fontSize="24px"
                            cursor="pointer"
                            color="black"
                            onClick={() => changeSort('name')}
                        />
                    )}
                  </Th>
                  <Th borderColor={borderColor} color="black" w="30%" fontSize='14px'>
                    {t("pages.employee.department")}
                  </Th>
                  <Th borderColor={borderColor} color="black" textAlign="center" fontSize='14px' w="20%">
                    {t("pages.employee.action")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData && tableData.map((item, index) => (
                  <Tr key={item.id}>
                    <Td borderColor={borderColor} textAlign="center">
                      <Text fontSize='14px'>
                        {index + 1 + (params.limit * (params.page - 1))}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text noOfLines={1} fontSize='14px'>
                        {item.code}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all" >
                      <Text noOfLines={1} fontSize='14px'>
                        {item.name}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all" >
                      <Text noOfLines={1} fontSize='14px'>
                        {/* {item?.department?.map((department, index) => department?.name + (item?.department.length - 1 > index ? ',' : ''))} */}
                        {item?.department}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} textAlign="center">
                      {
                        item?.is_admin ?
                          <PrimaryButton
                            text={t("pages.employee.confirm")}
                            size={'xs'}
                            styles={{ fontSize: '14px' }}
                            onClick={() => {
                              open('modalConfirmAdmin')
                              setCurrentId(item.id)
                              setSelectedName(item.name)
                            }}
                          />
                          :
                          <DeleteButton
                            text={t("pages.employee.delete")}
                            size={'xs'}
                            styles={{ fontSize: '14px' }}
                            onClick={() => {
                              open('modalDeleteAdmin')
                              setCurrentId(item.id)
                              setSelectedName(item.name)
                            }}
                          />
                      }
                    </Td>

                  </Tr>
                ))}
              </Tbody>
            </Table>
            {Math.ceil(total / params.limit) > 1 && (
              <Pagination total={total} perPage={params.limit} currentPage={params.page} setParams={setParams} />
            )}
          </TableContainer>
          {isLoading && (
            <Box textAlign="center" mt="40px">
              <Spinner></Spinner>
            </Box>
          )}
          {
            tableData && tableData.length === 0 && (
              <Box textAlign="center" pt="20px" color="gray.400">
                {t('messages.common_s003')}
              </Box>
            )
          }
          {
            error && (
              <Box textAlign="center" pt="20px" color="gray.400">
                {t('messages.common_s005')}
              </Box>
            )
          }
        </CardBody>
      </Card>
      <ModalConfirmAdmin
        isOpen={modalState.modalConfirmAdmin}
        content={`${selectedName} ${t("pages.employee.content_confirm")}`}
        onClose={() => close('modalConfirmAdmin')}
        onSubmit={() => onSubmit(currentId)}
      />
      <ModalConfirmAdmin
        isOpen={modalState.modalDeleteAdmin}
        content={`${selectedName} ${t("pages.employee.content_delete")}`}
        onClose={() => close('modalDeleteAdmin')}
        onSubmit={() => onSubmit(currentId)}
      />
    </Flex>
  );
}

export default EmployeeList;