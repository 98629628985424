import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import style from './style.module.scss'

export default function ESelectWithSearch({ name, control, options, placeholder, onChange }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl isInvalid={!!error}>
          <Select {...field} options={options} menuPosition='fixed' placeholder={placeholder} className={style.font_14} onChange={onChange} />
          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  )
}
