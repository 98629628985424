// Chakra imports
import {
  Portal,
  useDisclosure,
  Stack,
  Box,
  useColorMode
} from '@chakra-ui/react'
import Configurator from 'components/Configurator/Configurator'
import Footer from 'components/Footer/Footer.js'
import {
  ArgonLogoDark,
  ArgonLogoLight,
  ChakraLogoDark,
  ChakraLogoLight
} from 'components/Icons/Icons'
// Layout components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import React, { useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { dashRoutes as routes } from 'routers'
// Custom Chakra theme
import FixedPlugin from '../components/FixedPlugin/FixedPlugin'
// Custom components
import MainPanel from '../components/Layout/MainPanel'
import PanelContainer from '../components/Layout/PanelContainer'
import PanelContent from '../components/Layout/PanelContent'
import bgAdmin from 'assets/img/admin-background.png'
import { STORAGE } from 'constants/common'
import { getCookie } from 'utils'
import { extendTheme } from '@chakra-ui/react'

export default function Dashboard(props) {
  const { ...rest } = props
  const location = useLocation()
  // states and functions
  const [fixed, setFixed] = useState(false)
  const { colorMode } = useColorMode()

  const accessToken = getCookie(STORAGE.ACCESS_TOKEN)

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps'
  }

  const getActiveRoute = (routes) => {
    let activeRoute = 'Administration'
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        activeRoute = routes[i].name
      }
    }
    return activeRoute
  }

  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views)
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar
        }
      }
    }
    return activeNavbar
  }
  // Render routers
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  document.documentElement.dir = 'ltr'
  // Chakra Color Mode
  return (
    <Box>
      <Box
        minH='100vh'
        w='100%'
        position='absolute'
        // bgImage={colorMode === 'light' ? bgAdmin : 'none'}
        // bg={colorMode === 'light' ? bgAdmin : 'navy.900'}
        bgColor={'#259f94'}
        bgSize='cover'
        top='0'
      />
      <Sidebar
        routes={routes.filter((route) => route.layout === '/admin')}
        logo={
          <Stack direction='row' spacing='12px' align='center' justify='center'>
            {colorMode === 'dark' ? (
              <ArgonLogoLight w='74px' h='27px' />
            ) : (
              <ArgonLogoDark w='74px' h='27px' />
            )}
            <Box
              w='1px'
              h='20px'
              bg={colorMode === 'dark' ? 'white' : 'gray.700'}
            />
            {colorMode === 'dark' ? (
              <ChakraLogoLight w='82px' h='21px' />
            ) : (
              <ChakraLogoDark w='82px' h='21px' />
            )}
          </Stack>
        }
        display='none'
        {...rest}
      />
      <MainPanel
        w={{
          sm: 'calc(100% - 200px)',
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText=''
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            routes={routes}
            {...rest}
          />
        </Portal>
        {accessToken && getRoute() ? (
          <PanelContent>
            <PanelContainer>
              <Switch>
                {getRoutes(routes)}
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : (
          <Redirect to='/auth/login' />
        )}
      </MainPanel>
    </Box>
  )
}
