import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink, Text,
  useColorModeValue,
  Flex
} from '@chakra-ui/react'
import { useLocation, Link, useRouteMatch, matchPath } from 'react-router-dom'
import { t } from 'i18next'
import React, { useState } from 'react'
import { HomeIcon } from 'components/Icons/Icons'

const EBreadcrumb = ({ routes }) => {
  let mainText = useColorModeValue('white', 'gray.200')
  let secondaryText = useColorModeValue('white', 'gray.200')

  const location = useLocation()

  const getBreadcrumb = (routes) => {

    for (let i = 0; i < routes.length; i++) {
      const match = matchPath(location.pathname, {
        path: routes[i].layout + routes[i].path,
        exact: true,
        strict: false
      })

      if (match && routes[i].breadCrumb) {
        return routes[i].breadCrumb.map((crumb, idx) => (
          <BreadcrumbItem color={mainText} key={idx}>
            <BreadcrumbLink
              as={Link}
              to={crumb.link ? crumb.link : '#'}
              color={secondaryText}
            >
              {t(crumb.label)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))
      }
    }
  }

  return (
    <Breadcrumb>
      {/*<BreadcrumbItem color={mainText}>
        <BreadcrumbLink as={Link} to={'/admin'} color={secondaryText}>
          <HomeIcon />
        </BreadcrumbLink>
      </BreadcrumbItem>*/}
      {/* {getBreadcrumb(routes)} */}
    </Breadcrumb>
  )
}

export default EBreadcrumb
