import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { del, get, put, post } from 'api/requests'
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const getListDocument = async (params) => await get('document/list', params)
const apiCreateDocument = (params) => post(`document/create`, params)
const importCsv = (payload) => post(`document/import`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
const apiUpdateDocument = (payload) =>
  post(`document/update/${payload.id}`, payload)
const apiDeleteDocument = (id) => del(`document/delete/${id}`)
const detailDocument = (id) => get(`document/detail/${id}`)

export const useGetListDocument = (params = {}) => {
  return useQuery(['document/list', params], () => getListDocument(params), {
    enable: true
  })
}

export const useCreateDocument = () => {

  const history = useHistory()

  return useMutation((data) => apiCreateDocument(data), {
    onSuccess: () => {
      toast.success(t('pages.document.create_success'))
      history.push('/admin/documents')
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message)
    }
  })
}

export const useUpdateDocument = () => {
  return useMutation((payload) => apiUpdateDocument(payload), {
    onSuccess: () => {
      toast.success(t('pages.document.update_success'))
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })
}

export const useDeleteDocument = () => {
  const history = useHistory()
  const queryClient = useQueryClient()
  return useMutation((id) => apiDeleteDocument(id), {
    onSuccess: (response) => {
      toast.success(`コースID ${response.result?.data?.id} ${t('pages.document.list.table.delete_success')}`)
      queryClient.invalidateQueries('document/list')
      history.push('/admin/documents')
    },
    onError: (error) => {
      toast.error(error.response?.data?.message)
    }
  })
}

export const useGetDetailDocument = (id, enabled) => {
  return useQuery(['getDocument', id], () => detailDocument(id), {
    enabled: enabled
  })
}

export const useImportCsv = () => {
  const queryClient = useQueryClient()
  return useMutation((payload) => importCsv(payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('document/list')
      toast.success(t('pages.document.update_success'))
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })
}
