import {

  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import DeleteButton from 'components/Buttons/DeleteButton';
import EInput from 'components/EInput'
import { t } from 'i18next'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import Pagination from 'components/Pagination/Pagination'
import { useState } from 'react'
import { HSeparator } from 'components/Separator/Separator'
import { useGetListDocument } from 'hooks/useDocument'
import { useUpdateEmployee } from "hooks/useEmployee";
const ModalConfirmAdmin = ({ isOpen, onClose, content, onSubmit,  }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay/>
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={"10px"}>
          {content}
        </ModalBody>
        <ModalFooter justifyContent={"center"}>
          <PrimaryButton
            text={t('common.ok')}
            styles={{
              mr: '15px'
            }}
            onClick={onSubmit}
          />
          <DeleteButton
            text={t('common.cancel')}
            onClick={onClose}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirmAdmin
