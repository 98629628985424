import { Flex, Spacer } from "@chakra-ui/react"
import ReactPaginate from "react-paginate"
import style from './style.module.scss'

const Pagination = (props) => {
  const { total, perPage, setParams, currentPage } = props

  const handlePageChange = (page) => {
    setParams(prevState => {
      const tempData = { ...prevState }
      tempData.page = page.selected + 1
      return tempData
    })
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

  return (
    <Flex>
      <Spacer />
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={Math.ceil(total / perPage)}
        previousLabel="<"
        pageClassName={style.pageItem}
        pageLinkClassName={style.pageLink}
        previousClassName={style.pageItem}
        previousLinkClassName={style.pageLink}
        nextClassName={style.pageItem}
        nextLinkClassName={style.pageLink}
        breakLabel="..."
        breakClassName={style.pageItem}
        breakLinkClassName={style.pageLink}
        containerClassName={style.pagination}
        activeClassName={style.active}
        renderOnZeroPageCount={null}
        forcePage={currentPage - 1}
      />
    </Flex>
  )
}

export default Pagination