import { Button } from '@chakra-ui/react'
import React from 'react'

const DisableButton = ({ text, onClick, type='button', isLoading}) => {
    return (
        <Button colorScheme='green' onClick={onClick} type={type} paddingLeft={'26px'} paddingRight={'26px'} disabled isLoading={isLoading} loadingText={text} fontSize="14px" size={'sm'}>
            {text}
        </Button>
    )
}

export default DisableButton
