// import
import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import AuthLayout from 'layouts/Auth.js'
import AdminLayout from 'layouts/Admin.js'
import Dashboard from 'views/Dashboard/Dashboard.js'
import Tables from 'views/Dashboard/Tables.js'
import SignIn from 'views/Pages/SignIn.js'
import Courses from 'views/Course/List.js'
import CourseDetail from 'views/Course/Detail'
import DocumentList from 'views/Document/List'
import EmployeeList from 'views/Employee/List'
import UpdateOrCreateDocument from 'views/Document/UpdateOrCreate'
import HandleLogin from 'views/Pages/HandleLogin'
import JobAssignHistoryList from 'views/JobAssignHistory/List'

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon
} from 'components/Icons/Icons'
import { STORAGE } from 'constants/common'
import { getCookie } from 'utils'
import CourseForm from 'views/Course/CourseForm'
import NotFound from 'views/NotFound'

const accessToken = getCookie(STORAGE.ACCESS_TOKEN)

export const dashRoutes = [
  {
    path: '/login',
    name: 'Login',
    icon: <StatsIcon color='inherit' />,
    component: SignIn,
    layout: '/auth'
  },
  {
    path: '/handle/login',
    name: '',
    icon: <DocumentIcon color='inherit' />,
    component: HandleLogin,
    layout: '/auth',
    hideMenu: true
  },
  {
    path: '/not-found',
    name: '',
    icon: <DocumentIcon color='inherit' />,
    component: NotFound,
    layout: '/admin',
    hideMenu: true
  },

  //COURSES
  {
    path: '/courses',
    name: 'pages.courses.title',
    sideBar: 'pages.courses.title',
    icon: <StatsIcon color='inherit' />,
    component: Courses,
    layout: '/admin',
    /*breadCrumb: [{ label: 'pages.courses.title', link: '/admin/courses' }]*/
  },
  {
    path: '/courses/detail/:courseId',
    name: 'pages.courses.detail.title',
    component: CourseDetail,
    layout: '/admin',
    hideMenu: true,
    /*breadCrumb: [
      { label: 'pages.courses.title', link: '/admin/courses' },
      { label: 'pages.courses.detail.title', link: null }
    ]*/
  },
  {
    path: '/courses/create',
    name: '',
    icon: <StatsIcon color='inherit' />,
    component: CourseForm,
    layout: '/admin',
    hideMenu: true,
    /*breadCrumb: [
      { label: 'pages.courses.title', link: '/admin/courses' },
      { label: 'pages.course_form.title_create', link: '/admin/courses/create' }
    ]*/
  },
  {
    path: '/courses/edit/:courseId',
    name: '',
    icon: <StatsIcon color='inherit' />,
    component: CourseForm,
    layout: '/admin',
    hideMenu: true,
    /*breadCrumb: [
      { label: 'pages.courses.title', link: '/admin/courses' },
      { label: 'pages.course_form.title_edit', link: null }
    ]*/
  },

  //DOCUMENTS
  {
    path: '/documents',
    name: 'pages.document.list.title',
    sideBar: 'pages.document.list.sidebar',
    icon: <DocumentIcon color='inherit' />,
    component: DocumentList,
    layout: '/admin',
    /*breadCrumb: [
      { label: 'pages.document.list.title', link: '/admin/documents' }
    ]*/
  },
  {
    path: '/documents/create',
    name: 'pages.document.title_create',
    icon: <StatsIcon color='inherit' />,
    component: UpdateOrCreateDocument,
    layout: '/admin',
    hideMenu: true,
    /*breadCrumb: [
      { label: 'pages.document.list.title', link: '/admin/documents' },
      { label: 'pages.document.title_create', link: null }
    ]*/
  },
  {
    path: '/documents/edit/:id',
    name: 'pages.document.title_edit',
    icon: <StatsIcon color='inherit' />,
    component: UpdateOrCreateDocument,
    layout: '/admin',
    hideMenu: true,
    /*breadCrumb: [
      { label: 'pages.document.list.title', link: '/admin/documents' },
      { label: 'pages.document.title_edit', link: null }
    ]*/
  },

  {
    path: '/employees',
    name: 'pages.employee.title',
    sideBar: 'pages.employee.title',
    icon: <DocumentIcon color='inherit' />,
    component: EmployeeList,
    layout: '/admin',
    /*breadCrumb: [
      { label: 'pages.employee.title', link: '/admin/employees' },
    ]*/
  },

  //JOB ASSIGN HISTORY
  {
    path: '/job-history/:id',
    name: 'pages.job_assign_history.list.title',
    hideMenu: true,
    icon: <DocumentIcon color='inherit' />,
    component: JobAssignHistoryList,
    layout: '/admin',
  }
]

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`/auth`} component={AuthLayout} />
        <Route path={`/admin`} component={AdminLayout} />
        <Redirect from='/' to='/admin' />
      </Switch>
    </BrowserRouter>
  )
}
export default Router
