import React, { useState } from "react";
import { t } from "i18next"
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spacer,
  useColorModeValue,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from "@chakra-ui/react/dist/chakra-ui-react.cjs";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Pagination from "components/Pagination/Pagination";
import SecondaryButton from "components/Buttons/SecondaryButton";
import { useGetAssignHistory } from "hooks/useAssignHistory";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";


const JobAssignHistoryList = () => {
  const { id } = useParams()
  const [searchName, setSearchName] = useState('')
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [params, setParams] = useState({
    page: 1,
    limit: 15,
    name: '',
    sortType: 1,
    sort: 'created_at'
  })

  const { data, isLoading, error } = useGetAssignHistory(id, params)
  const tableData = data?.result?.data
  const total = data?.result?.meta?.total


  const enterSearch = (e) => {
    if (e.key === 'Enter') {
      search()
    }
  }

  const search = () => {
    setParams(prevState => {
      const tempData = { ...prevState }
      tempData.name = searchName
      tempData.page = 1
      return tempData
    })
  }

  const changeSort = (typeParams, sortBy) => {
    setParams(prevState => {
      const tempData = { ...prevState }
      tempData.sort = sortBy
      tempData.sortType = typeParams
      return tempData
    })
  }

  const checkStatusText = (status) => {
    switch (status) {
      case 1:
        return ({
          color: 'green',
          content: t('pages.job_assign_history.list.status_success')
        })
      case 2:
        return ({
          color: 'orange',
          content: t('pages.job_assign_history.list.status_pendding')
        })
      default:
        return ({
          color: 'red',
          content: t('pages.job_assign_history.list.status_failed')
        })
    }
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "40px" }}>
      <Card>
        <CardHeader p="6px 0px 40px 0px">
          <Breadcrumb separator={'>'}>
            <BreadcrumbItem>
              <BreadcrumbLink href={`/admin/courses`} textDecoration={'none'}>{t('pages.courses.title')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href={`/admin/courses/detail/${id}`} textDecoration={'none'}>{t('pages.courses.detail.title')}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href={`/admin/job-history/${id}`} textDecoration={'none'}>{t('pages.job_assign_history.list.title')}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Box justifyContent={'space-between'} mt={'20px'}>
            <Text fontSize='xl' fontWeight='bold'>
              {t('pages.job_assign_history.list.title')}
            </Text>
          </Box>
          <Flex align="end">
            <Box w="50%">
              <FormControl>
                <Flex mt="20px">
                  <FormLabel w="150px" mt={{ base: "7px" }} fontSize="14px" textAlign={'end'} mr={'30px'} fontWeight={'bold'}>
                    {t("pages.job_assign_history.list.search_by_document")}
                  </FormLabel>
                  <Input w="200px" value={searchName} onChange={(e) => setSearchName(e.target.value)} onKeyDown={enterSearch} fontSize="14px" />
                  <Button
                    onClick={search}
                    ml="20px"
                    background="#007bff"
                    color="white"
                    fontSize="14px"
                    w="80px"
                    mt={'5px'}
                    _hover={{ background: "#007bff99" }}
                    size={'sm'}
                  >
                    {t("pages.document.list.search_form.search_button")}
                  </Button>
                </Flex>
              </FormControl>
            </Box>
            <Spacer />
          </Flex>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant="simple" w="100%" size={'xs'}>
              <Thead>
                <Tr>
                  <Th borderColor={borderColor} color="black" width="50px">
                  </Th>
                  <Th borderColor={borderColor} color="black" fontSize="14px">
                    {t("pages.job_assign_history.list.employee_id")}
                  </Th>
                  <Th borderColor={borderColor} color="black" fontSize="14px">
                    {t("pages.job_assign_history.list.course_id")}
                  </Th>
                  <Th borderColor={borderColor} color="black" fontSize="14px" w={'30%'}>
                    {t("pages.job_assign_history.list.course_name")}
                  </Th>
                  <Th borderColor={borderColor} color="black" fontSize="14px">
                    {t("pages.job_assign_history.list.start_at")}
                    {params.sortType === 0 && params.sort === 'created_at' ? (
                      <ChevronUpIcon
                        fontSize="24px"
                        cursor="pointer"
                        color="black"
                        onClick={() => changeSort(1, 'created_at')}
                      />
                    ) : (
                      <ChevronDownIcon
                        fontSize="24px"
                        cursor="pointer"
                        color="black"
                        onClick={() => changeSort(0, 'created_at')}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor} color="black" fontSize="14px">
                    {t("pages.job_assign_history.list.ended_at")}
                    {params.sortType === 0 && params.sort === 'ended_at' ? (
                      <ChevronUpIcon
                        fontSize="24px"
                        cursor="pointer"
                        color="black"
                        onClick={() => changeSort(1, 'ended_at')}
                      />
                    ) : (
                      <ChevronDownIcon
                        fontSize="24px"
                        cursor="pointer"
                        color="black"
                        onClick={() => changeSort(0, 'ended_at')}
                      />
                    )}
                  </Th>
                  <Th borderColor={borderColor} color="black" fontSize="14px">
                    {t("pages.job_assign_history.list.status")}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData && tableData.map((item, index) => (
                  <Tr key={index}>
                    <Td borderColor={borderColor} textAlign="center" fontSize="14px">
                      {(index + 1) + (params.limit * (params.page - 1))}
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                    {item?.employee?.code ? 
                    (<Text fontSize="14px">
                        ID: {item?.employee?.code} {item?.employee?.name}
                      </Text>) : 
                      (<Text fontSize="14px">
                        {t("pages.courses.detail.job_target_renewal_cycle")}
                      </Text>)}
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text fontSize="14px">
                        {item?.document?.course_code}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all" pr={'10px'}>
                      <Text fontSize="14px">
                        {item?.document?.name}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text fontSize="14px">
                        {item?.created_at ? moment(item?.created_at).add(9, 'hour').format('YYYY/MM/DD HH:mm') : ''}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text fontSize="14px">
                        {item?.ended_at ? moment(item?.ended_at).add(9, 'hour').format('YYYY/MM/DD HH:mm') : ''}
                      </Text>
                    </Td>
                    <Td borderColor={borderColor} whiteSpace="pre-line" wordBreak="break-all">
                      <Text fontSize="14px" color={checkStatusText(item?.status).color}>
                        {checkStatusText(item?.status).content}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          {isLoading && (
            <Box textAlign="center" mt="40px">
              <Spinner></Spinner>
            </Box>
          )}
          {
            tableData && tableData.length === 0 && (
              <Box textAlign="center" pt="20px" color="gray.400" fontSize="14px">
                {t('messages.common_s003')}
              </Box>
            )
          }
          {
            error && (
              <Box textAlign="center" pt="20px" color="gray.400" fontSize="14px">
                {t('messages.common_s005')}
              </Box>
            )
          }
          <Flex mt={'50px'} justifyContent={'space-between'}>
            <Box>
              <SecondaryButton
                text={t('pages.job_assign_history.list.button_back')}
                onClick={() => { history.back() }}
              />
            </Box>
            <Box>
              {Math.ceil(total / params.limit) > 1 && (
                <Pagination total={total} perPage={params.limit} currentPage={params.page} setParams={setParams} />
              )}
            </Box>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default JobAssignHistoryList;