import { Button } from '@chakra-ui/react'
import React from 'react'

const PrimaryButton = ({ text, onClick, type = 'button', styles, isLoading, size, disabled }) => {
  return (
    <Button colorScheme={''} backgroundColor={'#007bff'} onClick={onClick} type={type} sx={styles} disabled={disabled} isLoading={isLoading} loadingText={text} size={size ?? 'sm'} fontSize={'14px'}>
      {text}
    </Button>
  )
}

export default PrimaryButton
