import { Flex, Button, Input, Text, Box } from '@chakra-ui/react'
import {
  useCreateDocument,
  useGetDetailDocument,
  useUpdateDocument
} from '../../hooks/useDocument'
import { t } from 'i18next'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import * as yup from 'yup'
import EInput from 'components/EInput'
import ETextarea from 'components/ETextarea'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import SecondaryButton from 'components/Buttons/SecondaryButton'
import { useState, useEffect } from 'react'
import { PATHNAME, SIZE_3MB, SUPPORTED_IMAGE_FORMATS } from 'constants'
import { useParams } from 'react-router-dom'

const DocumentForm = () => {
  const params = useParams()
  const currentUrl = location.pathname
  const isCreatePage = currentUrl === PATHNAME.CREATE_DOCUMENT

  const mutationCreate = useCreateDocument()
  const mutationUpdate = useUpdateDocument()
  const { data: data } = useGetDetailDocument(params.id, !isCreatePage)


  const onSubmit = (data) => {
    if (isCreatePage) {
      mutationCreate.mutate(data)
    } else {
      mutationUpdate.mutate({ ...data, id: params.id })
    }
  }

  useEffect(() => {
    if (data) {
      reset({
        name: data.result.data.name,
        description: data.result.data.description,
        course_code: data.result.data.course_code,
      })
    }
  }, [data?.result?.data])

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(t('messages.required'))
      .max(255, t('messages.common_e002')),
    course_code: yup
      .string()
      .required(t('messages.required')),
    description: yup
      .string()
      .max(1000, t('messages.common_e003'))
  })

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      course_code: ''
    },
    resolver: yupResolver(schema)
  })

  return (
    <Flex direction='column' pt={{ base: '120px', md: '40px' }}>
      <Card overflowx={{ sm: 'hidden', xl: 'hidden' }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Flex justifyContent={'space-between'}>
            <Text fontSize='xl' fontWeight='bold'>
              {isCreatePage
                ? t('pages.document.title_create')
                : t('pages.document.title_edit')}
            </Text>
          </Flex>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex gap={'10px'} align={'center'} mb={'20px'}>
              <Box w={'15%'}>
                <Text fontSize={'14px'}>{t('pages.document.name')}</Text>
              </Box>
              <Box width={'50%'}>
                <EInput name='name' placeholder='' control={control} />
              </Box>
            </Flex>
            <Flex gap={'10px'} align={'center'} mb={'20px'}>
              <Box w={'15%'}>
                <Text fontSize={'14px'}>{t('pages.document.course_id')}</Text>
              </Box>
              <Box width={'50%'}>
                <EInput name='course_code' placeholder='' control={control} />
              </Box>
            </Flex>
            <Flex gap={'10px'} mb={'20px'}>
              <Box w={'15%'} mt={'8px'}>
                <Text fontSize={'14px'}>{t('pages.document.description')}</Text>
              </Box>
              <Box width={'50%'}>
                <ETextarea
                  name='description'
                  placeholder=''
                  control={control}
                />
              </Box>
            </Flex>
            <Flex justifyContent={'end'} gap={'20px'} paddingY={'30px'}>
              <PrimaryButton text={isCreatePage ? t('pages.document.submit_create') : t('pages.document.submit_update')} type='submit' />
              <SecondaryButton
                text={t('pages.document.cancel')}
                onClick={() => history.go(-1)}
              />
            </Flex>
          </form>
        </CardBody>
      </Card>
    </Flex>
  )
}

export default DocumentForm
