import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get, post } from 'api/requests';

const getListEmployee = async (params) => await post('get-employee', params)
const getListAdmin = async (params) => await get('get-admin-list', params)
const updateEmployee = async (id) => await post(`update-employee/${id}`)
const getListEmployeeByCourseId = async (id, params) => await get(`get-employee-course/${id}`, params)

export const useGetListEmployee = (params = {}) => {
  return useQuery(['get-employee', params], () => getListEmployee(params), {
    enable: true
  })
}

export const useGetListAdmin = (params = {}) => {
  return useQuery(['get-admin-list', params], () => getListAdmin(params), {
    enable: true
  })
}

export const useGetEmployeeByCourseId = (params = {}, id) => {
  return useQuery(['get-employee-course', params, id], () => getListEmployeeByCourseId(id, params), {
    enable: true
  })
}

export const useUpdateEmployee = (id) => {
  const queryClient = useQueryClient()
  return useMutation((id) => updateEmployee(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('get-employee')
      queryClient.invalidateQueries('get-admin-list')
    }
  })
}