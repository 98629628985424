import { Box, Center, Flex, Image, Text } from '@chakra-ui/react'
import { t } from "i18next"
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import React from 'react'
import notFoundImg from 'assets/img/404.avif'
import PrimaryButton from 'components/Buttons/PrimaryButton'
import { useHistory } from 'react-router-dom'

const NotFound = () => {
  const history = useHistory()
  return (
    <Flex direction='column' pt={{ base: '120px', md: '40px' }}>
      <Card overflowX={'hidden'} pb='0px'>
        <CardBody marginLeft='30px' display='flex'>
          <Center h={'90vh'} w={'100%'}>
            <Box w={'500px'}>
              <Image src={notFoundImg} alt='' />
              <Center>
                <PrimaryButton
                  text={t('pages.job_assign_history.list.button_back')}
                  onClick={() => history.go(-2)}
                />
              </Center>
            </Box>
          </Center>
        </CardBody>
      </Card>
    </Flex>
  )
}

export default NotFound
